.inputBox1{
    background: transparent;
    border: none;
    border-bottom: 1.5px solid #125912;
    border-radius: 0px;
    padding-left: 0px;
    padding-bottom: 10px;
}
.inputBox1::placeholder {
    color: rgb(10 59 10);
    font-size: 14px;
    font-weight: 600;

  }


  
  .inputBox1:focus{
    outline: none;
}
.inputBox1:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
    border-bottom: 1.5px solid #125912;
    /* color: white !important; */
  }